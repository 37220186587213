.admin-section {
    text-align: justify;
    flex: 1;
    max-width: fit-content;
    margin: 0 auto;
    text-align: center;
}

.admin-section table {
    margin: 0 auto;
}

.submissions-wrapper, .feedback-wrapper {
    overflow-x: scroll;
}

td.feedback-td {
    white-space: pre-line;
}