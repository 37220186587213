.latex-editor {
    height: 500px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.latex-editor .textarea-wrapper {
    padding: 5px;
    overflow-x: scroll;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.latex-editor .textarea-wrapper textarea {
    font-size: 1.2em;
    flex: 1;
    resize: none;
}

.latex-editor .preview-wrapper {
    padding: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.latex-editor .preview {
    border: 1px solid;
    padding: 10px;
    text-align: justify;
    overflow-x: scroll;
    overflow-y: scroll;
    flex: 1;
    white-space: pre-line;
}

.latex-editor .textarea-caption,
.latex-editor .preview-caption {
    text-align: center;
    padding: 2px;
}