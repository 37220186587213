.login-wrapper {
    padding: 10px;
}

.login-form {
    flex: 1;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    max-width: 500px;

    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: stretch;
}

.form-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: stretch;
}

.form-item input {
    flex: 1;
}

.login-error {
    color: red;
}