.problems-link-section {
    text-align: center;
}

.herbstzirkel-section {
    text-align: justify;
    flex: 1;
    max-width: 920px;
    margin: 30px auto;
}

.herbstzirkel-section h1,
.herbstzirkel-section h2 {
    text-align: center;
}

.herbstzirkel-section ul li {
    margin: 10px 0px;
}