.latex-help-table tr.custom-macro {
    background: #f6e58d;
}

.latex-help-section {
    text-align: justify;
    flex: 1;
    max-width: fit-content;
    margin: 0 auto;
}

.latex-help-section h2 {
    text-align: center;
}