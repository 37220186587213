.dashboard-section {
    max-width: 920px;
    margin: 0 auto;
}

.dashboard-section h2 {
    text-align: center;
}

.dashboard-problems {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}

.dashboard-problem-link {
    flex: 0 0 58px;
    width: 58px;
    height: 58px;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 5px;
    background: lightgray;
    text-decoration: none;
}

.dashboard-problem-link.active {
    color: black;
    background: #3498db;
    border: 1px solid #3498db;
    box-shadow: 0 0 10px #3498db;
}

.dashboard-problem-link .dashboard-problem-number {
    flex: 1;
    color: black;
    font-size: .9em;
}

.dashboard-problem-link.active .dashboard-problem-number {
    color: black;
}

.dashboard-problem-link .dashboard-problem-score {
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 4px 4px;
    color: black;
}

.dashboard-problem-link .dashboard-problem-icon {
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
}

.dashboard-problem-link .dashboard-problem-icon img {
    width: 40px;
}