.scoreboard-section {
    text-align: justify;
    flex: 1;
    max-width: fit-content;
    margin: 0 auto;
    text-align: center;
}

.scoreboard-wrapper {
    overflow-x: scroll;
}

table.scoreboard td.score {
    text-align: center;
    width: 35px;
}