.colorbox {
    border-radius: 10px;
    background-color: lightgray;
    margin: 15px 0px;
}

.colorbox-header {
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 10px 10px 0px 0px;
}

.colorbox-text::-webkit-scrollbar {
    display: none;
}

.colorbox-text {
    text-align: justify;
    padding: 5px;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    /* scrollbar-width: none;  /* Firefox */
}

.colorbox-footer {
    padding: 5px;
}

.colorbox-footer p {
    margin: 0px;
}