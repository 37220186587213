html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: 100vh;
}

div#root {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-content: stretch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*nav.navbar {
  position: fixed;
  width: 100%;
  height: 50px;
  background: #f39c12;
  top: 0px;
}

nav.navbar a {
  display: inline-block;
  padding: 5px 15px;
  height: 40px;
  line-height: 40px;
  text-decoration: none;
  color: #2c3e50;
}*/

/*main.main-wrapper {
  margin-top: 50px;
  padding: 10px;
}*/

body {
  font-size: 1.05em;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.katex { font-size: 1.1em !important; }

a {
  color: #2980b9;
  cursor: pointer;
}

a.a-button {
  color: black;
  background-color: #f1c40f;
  text-decoration: none;
  height: 25px;
  padding: 10px;
  display: inline-block;
  line-height: 25px;
  cursor: pointer;
}

div.loading-message {
  text-align: center;
}

main.main-wrapper {
  padding: 10px;
  flex: 1;
  overflow-x: scroll;
}

main.main-wrapper * {
  scroll-margin-top: 50px;
}

input[type=text], input[type=password], input[type=button], input[type=submit] {
  font-size: 1.2em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    padding: 10px;
    margin: 5px 0px;
}

input[type=text], input[type=password] {
  border: 1px solid #7f8c8d;
}

input[type=button], input[type=submit] {
  background-color: #f1c40f;
  border: 0px;
  margin: 5px 0px;
  cursor: pointer;
}

table {
  border: 1px solid;
  border-collapse: collapse;
  text-align: center;
}

td, th {
  padding: 10px;
  border: 1px solid;
}

:root {
  --score0: #FF0D0D;
  --score1: #F98404;
  --score2: #F9B208;
  --score3: #ffef3c;
  --border-score3: #ebdc37;
  --score4: #F1F864;
  --border-score4: #d8df5a;
  --score5: #BCEB3C;
  --score6: #8ae429;
  --score7: #6bb120;
  --feedback-color: #ff9d93;
}

.score0 {
  background-color: var(--score0);
}

.border-score0 {
  border-color: var(--score0);
}

.score1 {
  background-color: var(--score1);
}

.border-score1 {
  border-color: var(--score1);
}

.score2 {
  background-color: var(--score2);
}

.border-score2 {
  border-color: var(--score2);
}

.score3 {
  background-color: var(--score3);
}

.border-score3 {
  border-color: var(--border-score3);
}

.score4 {
  background-color: var(--score4);
}

.border-score4 {
  border-color: var(--border-score4);
}

.score5 {
  background-color: var(--score5);
}

.border-score5 {
  border-color: var(--score5);
}

.score6 {
  background-color: var(--score6);
}

.border-score6 {
  border-color: var(--score6);
}

.score7 {
  background-color: var(--score7);
}

.border-score7 {
  border-color: var(--score7);
}

div.feedback {
  background-color: var(--feedback-color);
  padding: 5px;
  border-radius: 10px;
}

span.feedback {
  background-color: var(--feedback-color);
  padding: 3px 5px;
  border-radius: 5px;
}

span.strikeout {
  padding: 0px 4px;
  background: linear-gradient(to top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%);
}