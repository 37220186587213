.submission {
    border-radius: 10px;
    /* background-color: lightgray; */
    margin: 10px 0px;
    border-width: 1px;
    border-style: solid;
}

.submission.border-pending {
    border-color: #95a5a6;
}

.submission-header {
    padding: 5px;
    border-radius: 9px 9px 0px 0px;
    display: flex;
    flex-direction: row;
}

.submission-header.pending {
    background-color: #95a5a6;
}

.submission-header-index {
    flex: 1;
}

.submission-content {
    padding: 5px;
    white-space: pre-line;
}

.submission-code {
    padding: 5px;
    background: white;
    border-radius: 5px;
    white-space: pre-line;
}

.submission-feedback {
    padding: 5px;
    background: white;
    border-radius: 5px;
}