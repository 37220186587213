.home-section {
    max-width: 930px;
    margin: 0 auto;
    text-align: justify;
}

.home-section h2, .home-section h3 {
    text-align: center;
}

.settings div {
    background: lightgray;
    padding: 5px;
    margin: 10px 0px;
}