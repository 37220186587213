nav.navbar {
    background: #f1c40f;
    height: 50px;
    flex: 0 0 50px;

    position: sticky;
    top: 0;
    z-index: 100;

    display: flex;
    flex-direction: column;
}

nav.mobile-navbar {
    background: #f1c40f;
    position: fixed;
    height: calc(100% - 50px);
    width: 100%;
    z-index: 200;
    top: 50px;
    
    display: flex;
    flex-direction: row;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex: 1;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

nav.mobile-navbar ul {
    flex-direction: column;
    justify-content: flex-start;
}

nav ul li {
    flex: 1;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
}

nav.mobile-navbar ul li {
    flex: 0 1 50px;
    height: 50px;
}

nav a {
    flex: 1;
    display: block;
    text-decoration: none;
    color: black;
    font-size: 1.4em;

    display: flex;
    justify-content: center;
    align-items: center;
}

nav a.active {
    background: #f39c12;
}